.cmdq-style {
    background: #406193;
    color: white;
    background-position: bottom left;
    background-repeat: no-repeat;
    background-size: 27%;
}

.cmdq-header {
    display: flex;
    align-items: center;
    justify-content: center;    
}

.cmdq-img {
    width: 230px;
    height: 600px;
    object-fit: cover;
    position: absolute;
    bottom: 0;
    object-position: 50% 35px;
}

@media only screen and (min-height: 850px) and (min-width: 768px) and (max-width: 920px){
    .cmdq-style {
        background: #406193;
        height: 100vh;
        color: white;
        background-position: bottom left;
        background-repeat: no-repeat;
        background-size: 27%;
    }    
}

@media only screen and (min-height: 780px) and (min-width: 921px){
    .cmdq-style {
        background: #406193;
        height: 100vh;
        color: white;
        background-position: bottom left;
        background-repeat: no-repeat;
        background-size: 27%;
    }    
}

@media only screen and (min-height: 740px) and (min-width: 1312px){
    .cmdq-style {
        background: #406193;
        height: 100vh;
        color: white;
        background-position: bottom left;
        background-repeat: no-repeat;
        background-size: 27%;
    }    
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .cmdq-img {
        width: 220px;
        height: 490px;
        object-fit: cover;
        position: absolute;
        bottom: 0;
        object-position: 50% 35px;
    }
}

@media only screen and (min-width: 769px) and (max-width: 1199px) {
    .cmdq-img {
        width: 160px;
        height: 330px;
        object-fit: cover;
        position: absolute;
        bottom: 20%;
    }
}

@media only screen and (max-width: 768px) {
    .cmdq-img {
        width: 160px;
        height: 330px;
        object-fit: cover;
        position: relative !important;
    }

    .cmdq-img-center-mobile {
        text-align: center;
    }
    
    .cmdq-style {
        height: auto;
    }
}

.cmdq-sub-header {
    background: white;
    color: #6c6c6c;
    padding: 0.5rem 1.5rem;
    margin: 1rem 0.2rem;
    font-size: 15px;
}

.cmdq-table {
    color: white !important;
    font-size: 12px;
    text-align: center;
}

.cmdq-table tbody th {
    text-align: left;
    min-width: 9rem;
}

.cmdq-table thead th {
    max-width: 5rem;
    min-width: 5rem;
    word-break: break-word;
}

.border-top-hidden {
    border-top: hidden;
}

.border-left-hidden {
    border-left: hidden;
}

/* CSS for mobile specific only */
@media only screen and (max-width: 768px) {
    .display-block-mobile {
        display: block !important;
    }
}
  