.sidebar {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    min-height: 100vh !important;
    z-index: 100;
    width: 10rem;
    padding: 1rem;
    background: #234a86;
}

#sidebar-wrapper{
    min-height: 100vh !important;
    width: 100vw;
    margin-left: -1rem;
    -webkit-transition: margin .25s ease-out;
    -moz-transition: margin .25s ease-out;
    -o-transition: margin .25s ease-out;
    transition: margin .25s ease-out;
}

#sidebar-wrapper .sidebar-heading {
    padding: 0.875rem 1.25rem;
    font-size: 1.2rem;
}

#page-content-wrapper {
    min-width: 0;
    width: 100%;
}

.admin-filter {
    background: #d4e3f1;
    padding: 1rem;
    margin-bottom: 1rem;
}

.display-flex {
    display: flex;
}

.btn-download-filter {
    max-width: 7rem !important;
    font-size: 14px !important;
    margin-right: 5px !important;
}

.min-width-fit-content {
    min-width: fit-content !important;
}

.margin-right-5px {
    margin-right: 5px;
}

.bg-aliceblue {
    background: aliceblue !important;
}

.admin-header p {
    font-size: 25px;
}

.admin-header {
    margin-top: 2rem !important;
}

.circle-background,
.circle-progress {
  fill: none;
}

.circle-background {
  stroke: #ddd;
  stroke-width: 3px;
}

.circle-progress {
  stroke: #14005b !important;
  stroke-linecap: square;
  stroke-linejoin: round;
}

.circle-text {
  font-size: 3em;
  font-weight: bold;
  fill: #dd8429 !important;
}

.circle-sub-text {
  font-size: 10px;
  font-weight: bold;
  fill: #dd8429 !important;
}

.report-table {
    background: white;
    border: 2px solid orange !important;
}