.text-right {
  text-align: right !important;
}

.text-left {
  text-align: left !important;
}

.text-center {
  text-align: center !important;
}

.font-weight-bold {
  font-weight: bold;
}

.width-full {
  width: 100%;
}

.width-50p {
  width: 50%;
}

.max-width-70rem {
  max-width: 70rem !important;
}

.btn-primary {
  background: #14005b !important;
}

.btn-primary:hover {
  background: #22009b !important;
}

.position-relative {
  position: relative;
}

.top-20 {
  top: 20%;
}

.height-100-vh {
  height: 100vh;
}

.login-bg {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.normal-login-bg {
  background-position: right;
  background-repeat: no-repeat;
  background-size: 70%;
}

.card-shadow {
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}

.padding-0 {
  padding: 0 !important;
}

.padding-1rem {
  padding: 1rem;
}

.margin-0 {
  margin: 0 !important;
}

.margin-top-1rem {
  margin-top: 1rem !important;
}

.margin-top-15p {
  margin-top: 15% !important;
}

.margin-5rem {
  margin: 5rem !important;
}

.margin-left-1rem {
  margin-left: 1rem !important;
}

.background-orange {
  background: #dd8429 !important;
}

.color-orange {
  color: #dd8429 !important;
}

.color-white {
  color: white !important;
}

.float-right {
  float: right;
}

/* CSS for desktop specific only */
@media only screen and (min-width: 768px) {
  .left-10-desktop {
    left: 10%;
  }
}
